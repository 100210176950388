<div class="row form-group">
    <label class="col-md-3"><strong>Social Security Number:</strong></label>
    <div class="col-md-8">
        <div class="row form-group" [class.has-danger]="ssn?.touched && ssn?.errors">
            <span class="m-t-05 col-md-3">SSN</span>
            <div class="col-md-8 p-l-0 p-r-0">
                <div *ngIf="ssn?.errors?.invalidSsn && ssn?.touched" class="form-control-feedback">
                    This SSN is already associated with another client
                </div>
                <div *ngIf="ssn?.errors?.noSpaces && ssn?.touched" class="form-control-feedback">
                    SSN cannot contain spaces
                </div>
                <input [formControl]="ssn"
                       class="form-control"
                       type="text"
                       name="ssn"
                       placeholder="SSN"
                       [imask]="ssnMask"
                       [minlength]="9"
                       [maxlength]="11"
                >
            </div>
        </div>
        <div class="row no-ssn-container">
            <div class="col-md-8 offset-md-3 p-l-0">
                <label class="custom-control custom-checkbox">
                    <input [formControl]="noSsn" class="form-control custom-control-input"
                           type="checkbox">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">This client does not have a SSN</span>
                </label>
            </div>
        </div>
    </div>
</div>
<div class="row form-group">
    <label class="col-md-3"><strong>Name: </strong></label>
    <div class="col-md-8">
        <div class="row form-field-spacing">
            <span class="m-t-05 col-md-3">Prefix</span>
            <select [formControl]="prefix" class="form-control col-md-8" name="prefix">
                <option value="null">No Prefix</option>
                <option *ngFor="let prefix of prefixes" [value]="prefix.key">{{prefix.lookupValue}}</option>
            </select>
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!firstName?.valid && firstName?.touched">
            <span class="m-t-05 required-label col-md-3">First Name</span>
            <input [formControl]="firstName" class="form-control col-md-8" type="text" name="firstName"
                   placeholder="First Name">
        </div>
        <div class="row form-field-spacing">
            <span class="m-t-05 col-md-3">Middle Initial</span>
            <input maxlength="10" [formControl]="middleInitial" class="form-control col-md-8" type="text"
                   name="midInitial"
                   placeholder="Middle Initial">
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!lastName?.valid && lastName?.touched">
            <span class="m-t-05 required-label col-md-3">Last Name</span>
            <input [formControl]="lastName" class="form-control col-md-8" type="text" name="lastName"
                   placeholder="Last Name">
        </div>
        <div class="row form-field-spacing">
            <span class="m-t-05 col-md-3">Suffix</span>
            <select [formControl]="suffix" class="form-control col-md-8" name="suffix">
                <option value="null">No Suffix</option>
                <option *ngFor="let suffix of suffixes" [value]="suffix.key">{{suffix.lookupValue}}</option>
            </select>
        </div>
    </div>
</div>
<div class="row form-group">
    <label class="col-md-3"><strong>Address: </strong></label>
    <div class="col-md-8">
        <div class="row form-field-spacing" [class.has-danger]="!address1?.valid && address1?.touched">
            <span class="m-t-05 required-label col-md-3">Address</span>
            <input [formControl]="address1" class="form-control col-md-8" type="text" name="address1"
                   placeholder="Address">
        </div>
        <div class="row form-field-spacing">
            <span class="m-t-05 col-md-3">Unit/Suite</span>
            <input [formControl]="address2" class="form-control col-md-8" type="text" name="address2"
                   placeholder="Unit/Suite">
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!city?.valid && city?.touched">
            <span class="m-t-05 required-label col-md-3">City</span>
            <input [formControl]="city" class="form-control col-md-8" type="text" name="city" placeholder="City">
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!state?.valid && state?.touched">
            <span class="m-t-05 required-label col-md-3">State</span>
            <select [formControl]="state" class="form-control col-md-8" type="text" name="state">
                <option value="null">Choose a State</option>
                <option *ngFor="let state of states" [value]="state.key">{{state.lookupValue}}</option>
            </select>
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!zipCode?.valid && zipCode?.touched">
            <span class="m-t-05 required-label col-md-3">Zipcode</span>
            <input [formControl]="zipCode" class="form-control col-md-8" type="text" name="zipCode"
                   placeholder="Zipcode" [imask]="zipMask">
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!county?.valid && county?.touched">
            <span class="m-t-05 required-label col-md-3">County</span>
            <select [formControl]="county" class="form-control col-md-8" type="text" name="county">
                <option value="null">Choose a County</option>
                <option *ngFor="let county of countyList" [value]="county">{{county}}</option>
            </select>
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!phone?.valid && phone?.touched">
            <span class="m-t-05 col-md-3">Phone</span>
            <input [formControl]="phone" class="form-control col-md-8" type="text" name="phone"
                   placeholder="Phone" [imask]="phoneMask">
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!phoneExt?.valid && phoneExt?.touched">
            <span class="m-t-05 col-md-3">Phone Ext</span>
            <input maxlength="16" [formControl]="phoneExt" class="form-control col-md-8" type="text" name="phoneExt"
                   numbersOnly placeholder="Phone Ext">
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!phoneAlt?.valid && phoneAlt?.touched">
            <span class="m-t-05 col-md-3">Alternate Phone</span>
            <input [formControl]="phoneAlt" class="form-control col-md-8" type="text" name="phoneAlt"
                   placeholder="Alternate Phone" [imask]="phoneMask">
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!email?.valid && email?.touched">
            <span class="m-t-05 col-md-3">Email</span>
            <input [formControl]="email" class="form-control col-md-8" type="text" name="email" placeholder="Email">
        </div>
    </div>
</div>
<div class="row form-group">
    <label class="col-md-3"><strong>Personal Info: </strong></label>
    <div class="col-md-8">
        <div class="row form-field-spacing">
            <span class="m-t-05 col-md-3">Gender</span>
            <div class="col-md-8 p-l-0">
                <select name="gender" id="gender" class="custom-select form-control" [formControl]="gender">
                    <option value="">--Select Gender--</option>
                    <option *ngFor="let option of genders" [value]="option.key">{{option.lookupValue}}</option>
                </select>
            </div>
        </div>

        <div class="row form-field-spacing">
            <span class="m-t-05 col-md-3">Race/Ethnicity</span>
            <div class="col-md-8 p-l-0" [class.has-danger]="!race.valid">
                <select (change)="onRaceChange($event)" name="race" id="race" class="custom-select form-control"
                        [formControl]="race">
                    <option value="">--Select Race/Ethnicity--</option>
                    <option *ngFor="let option of races" [value]="option.key">{{option.lookupValue}}</option>
                </select>
            </div>
        </div>

        <div class="row align-items-center form-field-spacing">
            <span for="otherRace" class="m-t-05 col-md-3">If Other Race/Ethnicity, Please Specify:</span>
            <div class="col-md-8 p-l-0">
                <input [formControl]="otherRace"
                       [class.form-control-danger]="!otherRace.valid && otherRace.touched && race.value === 'other'"
                       class="form-control" type="text" value="" id="otherRace" placeholder="Other Race/Ethnicity">
            </div>
        </div>

        <div class="row form-field-spacing" [class.has-danger]="!birthYear?.valid && birthYear?.touched">
            <span class="m-t-05 required-label col-md-3">Birth Year</span>
            <input [formControl]="birthYear" numbersOnly [decimal]="false" class="form-control col-md-8" type="text"
                   name="birthYear"
                   placeholder="Birth Year">
            <span class="m-t-05 col-md-3" *ngIf="birthYear.getError('yearRange') && birthYear?.touched"></span>
            <span class="form-control-feedback"
                  *ngIf="birthYear.getError('yearRange') && birthYear?.touched">{{birthYear.getError('yearRange')}}</span>
        </div>

        <div class="row form-field-spacing" [class.has-danger]="!employmentStatus?.valid && employmentStatus?.touched">
            <span class="m-t-05 required-label col-md-3">Employment Status</span>
            <select [formControl]="employmentStatus" class="form-control col-md-8" name="employmentStatus">
                <option *ngFor="let status of employmentStatuses" [value]="status.key">{{status.lookupValue}}</option>
            </select>
        </div>
        <div class="row form-field-spacing" [class.has-danger]="!houseHoldSize?.valid && houseHoldSize?.touched">
            <span class="m-t-05 required-label col-md-3">Household Size</span>
            <input [formControl]="houseHoldSize" numbersOnly [decimal]="false" class="form-control col-md-8" type="text"
                   name="householdSize"
                   placeholder="Household Size"
                   (blur)="validateFederalPovertyLevel(); isDirty = true;"/>
        </div>
        <div class="row form-field-spacing" [class.has-warning]="FPLErrorMessage"
             [class.has-danger]="!houseHoldIncome?.valid && houseHoldIncome?.touched">
            <span class="m-t-05 col-md-3">Monthly Household Income</span>
            <input [formControl]="houseHoldIncome" numbersOnly [decimal]="false" class="form-control col-md-8"
                   type="text"
                   name="mthHouseIncome"
                   placeholder="Monthly Household Income"
                   (blur)="validateFederalPovertyLevel(); isDirty = true;"/>
            <span *ngIf="FPLErrorMessage" class="m-t-05  col-md-3"></span>
            <div class="form-control-feedback col-md-8"
                 *ngIf="FPLErrorMessage"
                 [innerHTML]="FPLErrorMessage"></div>
            <span class="m-t-05 col-md-3"
                  *ngIf="houseHoldIncome.getError('minAmount') && houseHoldIncome?.touched"></span>
            <span class="form-control-feedback"
                  *ngIf="houseHoldIncome.getError('minAmount') && houseHoldIncome?.touched">{{houseHoldIncome.getError('minAmount')}}</span>

        </div>

        <div class="row form-field-spacing"
             [class.has-danger]="!individualMonthlyIncomeControl?.valid && individualMonthlyIncomeControl?.touched">
            <span class="m-t-05 col-md-3">Individual Monthly Income</span>
            <input [formControl]="individualMonthlyIncomeControl" numbersOnly [decimal]="false"
                   class="form-control col-md-8"
                   type="text"
                   name="mthIndividualIncome"
                   placeholder="Individual Monthly Income"
            />
        </div>
    </div>
</div>
<div class="row form-group">
    <label class="col-md-5"><strong>Check if applicable to this Client</strong></label>
    <div class="col-md-7">
        <div class="row custom-checkbox-padding">
            <label class="custom-control custom-checkbox">
                <input [formControl]="isElderly" class="form-control custom-control-input" type="checkbox"
                       id="isElderly">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Is elderly (60 or older)</span>
            </label>
        </div>
        <div class="row custom-checkbox-padding">
            <label class="custom-control custom-checkbox">
                <input [formControl]="isDisabled" class="custom-control-input form-control" type="checkbox"
                       id="isDisabled">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Is disabled or chronically ill</span>
            </label>
        </div>
        <div class="row custom-checkbox-padding">
            <label class="custom-control custom-checkbox">
                <input [formControl]="hasChildrenUnderFive" class="custom-control-input form-control" type="checkbox"
                       id="hasChildrenUnderFive">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Has children under 5 years old</span>
            </label>
        </div>
        <div class="row custom-checkbox-padding">
            <label class="custom-control custom-checkbox">
                <input [formControl]="isVeteran" class="custom-control-input form-control" type="checkbox"
                       id="isVeteran">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Is veteran</span>
            </label>
        </div>
        <div class="row custom-checkbox-padding">
            <label class="custom-control custom-checkbox">
                <input [formControl]="isHomeless" class="custom-control-input form-control" type="checkbox"
                       id="isHomeless">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Is homeless</span>
            </label>
        </div>
    </div>
</div>
<div class="row form-group">
    <label class="col-md-5"><strong>Check if applicable to other household members</strong></label>
    <div class="col-md-7">
        <div class="row custom-checkbox-padding">
            <label class="custom-control custom-checkbox">
                <input [formControl]="membersAreElderly" class="custom-control-input form-control" type="checkbox"
                       id="membersAreElderly">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Are elderly (60 or older)</span>
            </label>
        </div>
        <div class="row custom-checkbox-padding">
            <label class="custom-control custom-checkbox">
                <input [formControl]="membersAreDisabled" class="custom-control-input form-control" type="checkbox"
                       id="membersAreDisabled">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Are disabled or chronically ill</span>
            </label>
        </div>
        <div class="row custom-checkbox-padding">
            <label class="custom-control custom-checkbox">
                <input [formControl]="membersHaveChildrenUnderFive" class="custom-control-input form-control"
                       type="checkbox" id="membersHaveChildrenUnderFive">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Have children under 5 years old</span>
            </label>
        </div>
    </div>
</div>
<div class="row form-group">
    <label class="col-md-12"><strong>Union Info (for labor union funding types only):</strong></label>
    <label class="col-md-3"><strong></strong></label>
    <div class="col-md-8">
        <div class="row form-field-spacing"
             [class.has-danger]="!form.get('unionInfo')?.valid && form.get('unionInfo')?.touched">
            <span class="m-t-05 required-label col-md-3">Union Info</span>
            <select [formControl]="unionInfo" class="form-control col-md-8" name="employmentStatus">
                <!--<option value="">Not Applicable</option>-->
                <option *ngFor="let unionInfo of unionInfoData"
                        [value]="unionInfo.key">{{unionInfo.lookupValue || 'Not Applicable'}}</option>
            </select>
        </div>
    </div>
</div>
<ng-content select="[footer]"></ng-content>
<div class="form-group col-xs-12">
    <div class="row">
        <span class="col-xs"></span>
        <div class="col-xs-3">

        </div>
        <span class="col-xs"></span>
    </div>
</div>
