import {
    EMPTY as observableEmpty,
    combineLatest,
    from as observableFrom,
    Observable,
    Subscription,
    of, combineLatestWith
} from 'rxjs';

import {filter, map, distinctUntilChanged, tap, mergeMap, delay, debounceTime, takeUntil} from 'rxjs/operators';
import {Component, OnInit, forwardRef, OnDestroy} from '@angular/core';
import {Params, Router, ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, FormControl, UntypedFormControl} from '@angular/forms';
import {Client, CountyResult, LookUp} from '../../../services/api.client';
import {ClientService} from '../../../services/ClientService';
import {Masking} from '../../../utilities/Masking';
import * as moment from 'moment';
import {Validators} from '../../../utilities/Validators';
import {FederalPovertyLevelService} from '../../../services/FederalPovertyLevelService';
import {CountyService} from '../../../services/CountyService';
import {AutoDisableParent} from '../../../directives/AutoDisableDirective';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectClientDataCurrentClient} from '../../../store/selectors/client.selectors';
import {
    selectSecurityStateDataCurrentUserUserType,
    selectSecurityStateDataSystemDataEmploymentStatus, selectSecurityStateDataSystemDataGender,
    selectSecurityStateDataSystemDataPrefix, selectSecurityStateDataSystemDataStates,
    selectSecurityStateDataSystemDataSuffix, selectSecurityStateDataSystemDataUnionInfo, selectSecurityStateDataSystemDataRace
} from '../../../store/selectors/security.selectors';
import {Race} from '../../../shared/enums/race';
import {Disposable} from '../../../utilities/Disposable';

@Component({
    selector: '[clientForm]',
    templateUrl: './ClientFormComponent.html',
    styleUrls: ['./ClientFormComponent.scss'],
    providers: [{provide: AutoDisableParent, useExisting: forwardRef(() => ClientFormComponent)}],
})

export class ClientFormComponent extends Disposable implements OnInit, OnDestroy {

    constructor(private _formBuilder: UntypedFormBuilder,
                private _clientService: ClientService,
                private _route: ActivatedRoute,
                private _federalPovertyLevelService: FederalPovertyLevelService,
                private _countyService: CountyService,
                private _router: Router,
                private _store: Store<fromRoot.AppState>) {
        super();
        this.prefixes$ = this._store.pipe(select(selectSecurityStateDataSystemDataPrefix));
        this.suffixes$ = this._store.pipe(select(selectSecurityStateDataSystemDataSuffix));
        this.states$ = this._store.pipe(select(selectSecurityStateDataSystemDataStates));
        this.employmentStatuses$ = this._store.pipe(select(selectSecurityStateDataSystemDataEmploymentStatus));
        this.loggedInUserType$ = this._store.pipe(select(selectSecurityStateDataCurrentUserUserType));
        this.unionInfoData$ = this._store.pipe(select(selectSecurityStateDataSystemDataUnionInfo));
        this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.genders$ = this._store.pipe(select(selectSecurityStateDataSystemDataGender));
        this.races$ = this._store.pipe(select(selectSecurityStateDataSystemDataRace));
    }

    get value(): any {
        const value = this.form?.value;

        if (this.ssn.disabled) {
            value.ssn = null;
            value.last4Ssn = null;
        }

        return value;
    }

    get ssn(): FormControl {
        return this.form.get('ssn') as FormControl;
    }

    get noSsn(): FormControl {
        return this.form.get('noSsn') as FormControl;
    }

    get prefix(): FormControl {
        return this.form.get('prefix') as FormControl;
    }

    get firstName(): FormControl {
        return this.form.get('firstName') as FormControl;
    }

    get middleInitial(): FormControl {
        return this.form.get('middleInitial') as FormControl;
    }

    get lastName(): FormControl {
        return this.form.get('lastName') as FormControl;
    }

    get suffix(): FormControl {
        return this.form.get('suffix') as FormControl;
    }

    get address1(): FormControl {
        return this.form.get('address1') as FormControl;
    }

    get address2(): FormControl {
        return this.form.get('address2') as FormControl;
    }

    get city(): FormControl {
        return this.form.get('city') as FormControl;
    }

    get state(): FormControl {
        return this.form.get('state') as FormControl;
    }

    get zipCode(): FormControl {
        return this.form.get('zipCode') as FormControl;
    }

    get county(): FormControl {
        return this.form.get('county') as FormControl;
    }

    get phone(): FormControl {
        return this.form.get('phone') as FormControl;
    }

    get phoneExt(): FormControl {
        return this.form.get('phoneExt') as FormControl;
    }

    get phoneAlt(): FormControl {
        return this.form.get('phoneAlt') as FormControl;
    }

    get email(): FormControl {
        return this.form.get('email') as FormControl;
    }

    get gender(): FormControl {
        return this.form.get('gender') as FormControl;
    }

    get birthYear(): FormControl {
        return this.form.get('birthYear') as FormControl;
    }
    get race(): FormControl {
        return this.form.get('race') as FormControl;
    }

    get otherRace(): FormControl {
        return this.form.get('otherRace') as FormControl;
    }

    get employmentStatus(): FormControl {
        return this.form.get('employmentStatus') as FormControl;
    }

    get houseHoldSize(): FormControl {
        return this.form.get('houseHoldSize') as FormControl;
    }

    get houseHoldIncome(): FormControl {
        return this.form.get('houseHoldIncome') as FormControl;
    }

    get isElderly(): FormControl {
        return this.form.get('isElderly') as FormControl;
    }

    get individualMonthlyIncomeControl(): FormControl {
        return this.form.get('individualMonthlyIncome') as FormControl;
    }

    get isDisabled(): FormControl {
        return this.form.get('isDisabled') as FormControl;
    }

    get hasChildrenUnderFive(): FormControl {
        return this.form.get('hasChildrenUnderFive') as FormControl;
    }

    get isVeteran(): FormControl {
        return this.form.get('isVeteran') as FormControl;
    }

    get isHomeless(): FormControl {
        return this.form.get('isHomeless') as FormControl;
    }

    get membersAreElderly(): FormControl {
        return this.form.get('membersAreElderly') as FormControl;
    }

    get membersAreDisabled(): FormControl {
        return this.form.get('membersAreDisabled') as FormControl;
    }

    get membersHaveChildrenUnderFive(): FormControl {
        return this.form.get('membersHaveChildrenUnderFive') as FormControl;
    }

    get unionInfo(): FormControl {
        return this.form.get('unionInfo') as FormControl;
    }

    phoneMask = Masking.phoneMask;
    ssnMask = {
        mask: '***-**-0000',
        lazy: true
    };
    zipMask = Masking.zipMask;

    isClientFederalPovertyLevelValid = true;

    prefixes: LookUp[];
    prefixes$: Observable<LookUp[]>;

    suffixes: LookUp[];
    suffixes$: Observable<LookUp[]>;

    states: LookUp[];
    states$: Observable<LookUp[]>;

    employmentStatuses: LookUp[];
    employmentStatuses$: Observable<LookUp[]>;

    unionInfoData: LookUp[];
    unionInfoData$: Observable<LookUp[]>;

    loggedInUserType: string;
    loggedInUserType$: Observable<string>;

    currentClient: Client;
    currentClient$: Observable<Client>;

    genders: LookUp[];
    genders$: Observable<LookUp[]>;

    form: UntypedFormGroup;

    races: LookUp[];
    races$: Observable<LookUp[]>;

    private _stateCountySub: Subscription;

    // countyList: string[] = ['county 1', 'county 2'];
    countyList: string[];
    defaultCounty: CountyResult;

    FPLErrorMessage = '';
    isDirty: boolean;

    ngOnInit(): void {

        this.prefixes$.pipe(takeUntil(this.$destroyed)).subscribe(prefix => this.prefixes = prefix);
        this.suffixes$.pipe(takeUntil(this.$destroyed)).subscribe(suffix => this.suffixes = suffix);
        this.states$.pipe(takeUntil(this.$destroyed)).subscribe(states => this.states = states);
        this.employmentStatuses$.pipe(takeUntil(this.$destroyed)).subscribe(employmentStatus => this.employmentStatuses = employmentStatus);
        this.loggedInUserType$.pipe(takeUntil(this.$destroyed)).subscribe(loggedInUserType => this.loggedInUserType = loggedInUserType);
        this.unionInfoData$.pipe(takeUntil(this.$destroyed)).subscribe(unionInfoData => this.unionInfoData = unionInfoData);
        this.currentClient$.pipe(takeUntil(this.$destroyed)).subscribe(currentClient => this.currentClient = currentClient);
        this.genders$.pipe(takeUntil(this.$destroyed)).subscribe(genders => this.genders = genders);
        this.races$.pipe(takeUntil(this.$destroyed)).subscribe(races => this.races = races);
        this.initForm();

        const routeParams = this._route.params.pipe(delay(0), mergeMap((params: Params) => {
            const id = params.id || this._route.parent.snapshot.params.id;
            if (!id || id === 'add') {
                this._clientService.setCurrentClient(new Client());
            } else if (id && (!this.currentClient || (this.currentClient && this.currentClient.id !== id))) {
                this._clientService.getClientById(id);
            }
            return of(params);
        }));

        combineLatest([routeParams, this.currentClient$])
            .pipe(filter(([, client]) => !!client))
            .subscribe(([, client]: [Params, Client]) => {
                const fields = Object.assign({}, client, {
                    ssn: (client.last4Ssn) ? '***-**-' + client.last4Ssn : null,
                    unionInfo: (client.unionInfo) ? client.unionInfo : 'Not Applicable',
                });
                this.form.patchValue(fields);
                if (!fields.last4Ssn) {
                    this.noSsn.setValue(true);
                }
            });

        this.validateFederalPovertyLevel();
    }

    initForm(): void {
        this.form = this._formBuilder.group({
            id: new FormControl(null),
            ssn: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(9, [/-/g, /_/g]),
                (control: FormControl) => {
                    const value = control.value;
                    if (value && value.indexOf(' ') !== -1) {
                        return { noSpaces: true };
                    }
                    return null;
                }
            ])),
            prefix: new FormControl(null),
            firstName: new FormControl(null, Validators.required),
            middleInitial: new FormControl(null),
            lastName: new FormControl(null, Validators.required),
            suffix: new FormControl(null),
            address1: new FormControl(null, Validators.required),
            address2: new FormControl(null),
            city: new FormControl(null, Validators.required),
            state: new FormControl(null, Validators.required),
            zipCode: new FormControl('', Validators.required),
            county: new FormControl(null, Validators.required),
            phone: new FormControl('', Validators.minLength(9, [/-/g, /_/g, /\(/g, /\)/g])),
            phoneExt: new FormControl(''),
            email: new FormControl(null, Validators.email),
            phoneAlt: new FormControl('', Validators.minLength(9, [/-/g, /_/g, /\(/g, /\)/g])),
            gender: new FormControl(null),
            race: [''],
            otherRace: [''],
            employmentStatus: new FormControl(null, Validators.required),
            unionInfo: new FormControl('Not Applicable', Validators.required),
            houseHoldSize: new FormControl(null, Validators.required),
            houseHoldIncome: new FormControl(0, [Validators.minAmount(0), Validators.required]),
            individualMonthlyIncome: new FormControl(0, [Validators.required, Validators.minAmount(0)]),
            birthYear: new FormControl(
                null,
                Validators.compose([Validators.required, Validators.yearRange(moment().subtract(150, 'years'), moment())])),
            isElderly: new FormControl(false),
            isDisabled: new FormControl(false),
            hasChildrenUnderFive: new FormControl(false),
            isVeteran: new FormControl(false),
            isHomeless: new FormControl(false),
            membersAreElderly: new FormControl(false),
            membersAreDisabled: new FormControl(false),
            createdByAgencyId: new FormControl(null),
            membersHaveChildrenUnderFive: new FormControl(false),
            noSsn: new FormControl('') // not on the actual object
        });

        const ssnCache: any = {};
        this.ssn
            .valueChanges
            .exists().pipe(
            map((ssn: string) => ssn.replace(/[\-_]/g, '')),
            filter((ssn: string) => ssn.length === 9),
            filter((ssn: string) => ssn.indexOf('*') === -1),
            combineLatestWith(this.currentClient$),
            mergeMap(([ssn, client]) => {
                if (ssnCache[ssn] !== undefined) {
                    return observableFrom([ssnCache[ssn]]);
                }
                return this._clientService.clientUniqueSsnCheck(ssn, client && client.id || null).pipe(
                    map((data) => ssnCache[ssn] = data.result));
            }), )
            .subscribe((isInvalidSsn) => {
                const errors = this.ssn.errors;
                if (isInvalidSsn) {
                    this.ssn.setErrors(Object.assign(errors || {}, {invalidSsn: true}));
                } else if (errors) {
                    delete errors.invalidSsn;
                    this.ssn.setErrors(Object.keys(errors).length === 0 ? null : errors);
                }
            });
        this.noSsn
            .valueChanges.pipe(
            distinctUntilChanged())
            .subscribe(function field(ssnNotRequired): void {
                const f: any = field;
                if (typeof f.previousSsn === 'undefined') {
                    f.previousSsn = this.ssn.value || '';
                }
                if (ssnNotRequired) {
                    f.previousSsn = this.ssn.value || '';
                    f.previousErrors = this.ssn.errors;
                    this.ssn.reset({value: null, disabled: true});
                } else {
                    this.ssn.reset({value: f.previousSsn || '', disabled: false});
                    this.ssn.markAsTouched();
                    this.ssn.setValidators(Validators.compose([Validators.required, Validators.minLength(9, [/-/g, /_/g])]));
                    this.ssn.setErrors(f.previousErrors);
                    f.previousSsn = '';
                    f.previousErrors = null;
                }
            }.bind(this));


        this._stateCountySub = combineLatest([this.state?.valueChanges, this.zipCode?.valueChanges]).pipe(
            debounceTime(0),
            mergeMap(([state, zipcode]) => this._countyService.getCountiesWithDefault(zipcode + '', state)))
            .subscribe((result) => {
                this.countyList = result.countyList;
                // this.countyList = ['county 1', 'county 2', 'county 3'];
                this.defaultCounty = result.defaultCounty;
                if (result.defaultCounty) {
                    this.county.setValue(result.defaultCounty.county);
                }
            });
    }

    // isValid(): boolean {
    //   if (super.isValid()) {
    //     return true;
    //   } else {
    //     this.markAsTouched();
    //     return false;
    //   }
    // }

    validateFederalPovertyLevel(): boolean {
        if (this.houseHoldSize.value && +this.houseHoldIncome.value) {
            this._federalPovertyLevelService.validateFederalPovertyLevel(
                this.houseHoldSize.value, +this.houseHoldIncome.value)
                .subscribe((result) => {
                    if (result.result > 2) {
                        this.isClientFederalPovertyLevelValid = false;
                        this.FPLErrorMessage = 'These HouseHold Size & Monthly Income values estimate the client is more than double the Federal Poverty Level.<br>\
                                                Make sure you have entered the <b>MONTHLY</b> income and that the value is accurate.';
                        return false;
                    } else {
                        this.FPLErrorMessage = '';
                        this.isClientFederalPovertyLevelValid = true;
                        return true;
                    }

                });
        } else {
            this.FPLErrorMessage = '';
            this.isClientFederalPovertyLevelValid = true;
            return true;
        }
    }

    submit(redirectToOverview = true): any {
        const client = this.value;
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return observableEmpty;
        }
        if (client.id) {
            const result = Client.fromJS({
                id: this.currentClient?.id,
                ssn: client?.ssn || null,
                last4Ssn: client?.last4Ssn || null,
                missingSsn: client?.missingSsn || false,
                prefix: client?.prefix || null,
                firstName: client?.firstName || null,
                lastName: client?.lastName || null,
                middleInitial: client?.middleInitial || null,
                suffix: client?.suffix || null,
                address1: client?.address1 || null,
                address2: client?.address2 || null,
                city: client?.city || null,
                state: client?.state || null,
                zipCode: client?.zipCode || null,
                county: client?.county || null,
                phone: client?.phone || null,
                birthYear: client?.birthYear || null,
                gender: client?.gender || null,
                houseHoldIncome: client?.houseHoldIncome === null ? null : +client?.houseHoldIncome,
                individualMonthlyIncome: client?.individualMonthlyIncome === null ? null : +client?.individualMonthlyIncome,
                houseHoldSize: client?.houseHoldSize || null,
                employmentStatus: client?.employmentStatus || null,
                unionInfo: client?.unionInfo || null,
                isElderly: client?.isElderly || false,
                isDisabled: client?.isDisabled || false,
                hasChildrenUnderFive: client?.hasChildrenUnderFive || false,
                isVeteran: client?.isVeteran || false,
                isHomeless: client?.isHomeless || false,
                membersAreElderly: client?.membersAreElderly || false,
                membersAreDisabled: client?.membersAreDisabled || false,
                membersHaveChildrenUnderFive: client?.membersHaveChildrenUnderFive || false,
                phoneAlt: client?.phoneAlt || null,
                createdByAgencyId: client?.createdByAgencyId || null,
                federalPovertyLevelDisplay: client?.federalPovertyLevelDisplay || null,
                phoneExt: client?.phoneExt || null,
                email: client?.email || null,
                race: client?.race || null,
                otherRace: client?.otherRace || null,
            });

            return this._clientService.updateClient(result)
                .pipe(
                    tap((id) => redirectToOverview && this._router.navigate(['/app/client', result.id]))
                );
        }
        return this._clientService.addClient(client)
            .pipe(
                tap((result) => redirectToOverview && this._router.navigate(['/app/client/', result.id]))
            );

    }

    submitNoRedirect(): any {
        return this.submit(false);
    }

    onRaceChange(event): void {
        const selectedRace = event.target.value;

        if (selectedRace === Race.Other) {
            this.form.get('otherRace').setValidators(Validators.required);
            this.form.get('otherRace').updateValueAndValidity();
            return;
        }

        this.form.get('otherRace').setValidators(null);
        this.form.get('otherRace').updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this._stateCountySub?.unsubscribe();
    }
}
